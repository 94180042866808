import React from 'react';
import './Navbar.css';
import {Button} from './Button'
import { useHistory } from 'react-router-dom'
import cargo1 from '../images/cargo-1.png'
import {socket} from '../Socket'
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'


function Navbar2(props) {
    const history = useHistory();
    

    return (
        /*<div>
        <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
            <Navbar.Brand>
                <img
                    src={cargo1}
                    width="70"
                    height="70"
                    className="d-inline-block align-top"
                    alt="Rusty Cargo logo"
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Text><Link to="/">Jackpot</Link></Nav.Text>
                <Nav.Text><Link to="/coinflip">Coinflip</Link></Nav.Text>
                <Nav.Text><Link to="/profile">Profile</Link></Nav.Text>
                <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                </NavDropdown>
            </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
        </div>*/

        <nav className="NavbarItems">
            <img className="navbar-logo" src={cargo1}></img>
            <div className="menu-icon" onClick={() => props.setClicked(!props.clicked)}>
                <i className={props.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
            </div>
            <ul className={props.clicked ? 'nav-menu active' : 'nav-menu'}>
                <li>
                    <Link className="nav-links m-auto" to="/">Jackpot</Link>
                </li>
                <li>
                    <Link className="nav-links m-auto" to="/coinflip">Coinflip</Link>
                </li>
                <li>
                    <Link className="nav-links m-auto" to="/profile">Profile</Link>
                </li>
                <li>
                    <Button onClick={() => {
                        if (props.userObject){
                            props.openDonate(true); 
                            //handleClick();//?
                            props.requestInventory();
                        }else{
                            props.setTrigger(true)
                        }
                    }}>Donate</Button>
                </li>
                <li>
                    <Button onClick={() => {
                        if (props.userObject){
                            //props.openInventory(true); 
				//console.log(props.userObject);
                            //handleClick();//?
                        }else{
                            props.setTrigger(true)
                        }
                    }}>{props.userObject ? 'Logout' : 'Sign In'}</Button>
                </li>
            </ul>
            
        </nav>
    )
}

export default Navbar2
