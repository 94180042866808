import {React, useEffect} from 'react'
import { socket } from '../Socket';
import './Popup.css'
import { useSelector, useDispatch } from "react-redux";
import {
    resetVars,
    setBalance,
    searchForItem,
    updateInventory,
    setSelected,
    removeSelected,
    toggleDescending,
    setMinAmount,
    setMaxAmount,
    setBonus,
    setTradeData,
    clearSelected,
    clearTradeTotal,
    closeTradePopup
  } from "../redux/shop";



function Popup(props) {
    const dispatch = useDispatch();

    
    return (props.trigger) ? (
        <div className="popup">
            <div className="popup-inner">
                <button className="close-btn" onClick={() => {
                    props.setTrigger(false)
                    dispatch(setTradeData({}))
                    dispatch(clearSelected([]))
                    dispatch(clearTradeTotal(0))
                    }}>X</button>
                { props.children }
            </div>
        </div>
    ) :"";
}

export default Popup
