import {
    configureStore
  } from "@reduxjs/toolkit";
  
  import appReducer from "./app";
  //import rouletteReducer from "./ducks/roulette";
  //import chatReducer from "./ducks/chat";
  import profileReducer from "./profile"
  //import towersReducer from "./ducks/towers"
  //import diceReducer from "./ducks/dice"
  //import landminesReduccer from "./ducks/landmines"
  //import wofReducer from "./ducks/wof"
  import shopReducer from "./shop"
  //import statetestReducer from "./ducks/statetest"
  //import pfReducer from "./ducks/pf"
  import chatReducer from "./chat";
  import jackpotReducer from './Jackpot'

  
  export const store = configureStore({
    reducer: {
        chat: chatReducer, 
        shop: shopReducer,
        profile: profileReducer,
        app: appReducer,
        jackpot: jackpotReducer
    },
  });