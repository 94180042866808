import './Profile.css';
import React from 'react';
import Popup from './Popup';
import {socket} from '../Socket'
import { useSelector, useDispatch } from "react-redux";
import { useState, useContext, useEffect } from 'react';
import { myContext} from '../context';
import {setJackpotTimer} from '../redux/Jackpot'
import line from '../images/line.png'
import rust from '../images/rust.png';
import rust_2 from '../images/rust_2.png'
import {
    resetVars,
    setBalance,
    searchForItem,
    updateInventory,
    setSelected,
    removeSelected,
    toggleDescending,
    setMinAmount,
    setMaxAmount,
    setBonus,
    setTradeData,
    editFlip,
    closeTradePopup,
    setJackpotItems,
    setFlips,
    setCurrentFlip
  } from "../redux/shop";
import CoinFlipDetails from './CoinFlipDetails';

const ListJackpots = ({ item }) => {
    const handleClick = () => socket.emit('resendWin', {hash: item.hash, gametype: 'jackpot'})
    const handleView = () => socket.emit('viewJackpot', {hash: item.hash})
    console.log('flip')
    const userObject = useContext(myContext);
    let state = 'lose'
    if(userObject){
        if(item.winner == userObject.id){
            state='win'
        }
    }


    return (
        <div hash={item._id} className={"flipEntry2 " + state}>
            <div className="row1">
                <div className="money">${item.totalPrice.toFixed(2)}</div>
                </div>
                <div className="row2">
                
                </div>
                <div className="row32">

                    <button onClick={() => {
                        //console.log('view ' + item._id)
                        handleClick();

                    }} style={item.winTradeAccepted || state !='win' ? {display: 'none'} : {}}>
                        Resend Trade
                    </button>
                    <button onClick={() => {
                        //console.log('view ' + item._id)
                        handleView();

                    }}>
                        View
                    </button>
                </div>
        </div>
    );
};

const ListCoinflips = ({ item }) => {
    const handleClick = () => socket.emit('resendWin', {hash: item.hash, gametype: 'coinflip'})
    const handleView = () => socket.emit('viewCF', {hash: item.hash})
    console.log('flip')
    const userObject = useContext(myContext);
    let state = 'lose'
    if(userObject){
        if(item.creator.pick == item.winner){
            if(userObject.id == item.creator.userid){
                state = 'win'
            }
        }
        if(item.creator.pick != item.winner && item.opponent.userID == userObject.id){
            state='win'
        }
    }
    
    return (
        <div hash={item._id} className={"flipEntry2 " + state}>
            <div className="row1">
                <div className="money">${(item.creator.total + item.opponent.totalPrice).toFixed(2)}</div>
                </div>
                <div className="row2">
                
                </div>
                <div className="row32">

                    <button onClick={() => {
                        //console.log('view ' + item._id)
                        handleClick()

                    }} style={item.winTradeAccepted || state !='win' ? {display: 'none'} : {}}>
                        Resend Trade
                    </button>
                    <button onClick={() => {
                        //console.log('view ' + item._id)
                        handleView();

                    }}>
                        View
                    </button>
                </div>
        </div>
    );
};
const JackpotItem = ({ stuff }) => {
    const selected = useSelector(state => state.shop.selected);
    const dispatch = useDispatch();
    return (
        <div title={stuff.market_hash_name} className='jackpotItem'>
            <img src={'https://community.cloudflare.steamstatic.com/economy/image/' + stuff.image}></img>
            <p>{stuff.price}</p>
            
        </div>
    );
  };

const JackpotEntry = ({ item, jackpotTotal }) => {
    const selected = useSelector(state => state.shop.selected);
    const jackpotItems = useSelector((state) => state.shop.jackpotItems)
    const dispatch = useDispatch();
    //console.log(item);
    return (
        <div>
            <div className="jackpotEntryHeader">
                <div className="center">
                    <img className="jackpotUser" src={item.profileImage}></img>
                    <p>{item.name + ' $'+item.totalPrice.toFixed(2)+' | '+(item.totalPrice/jackpotTotal*100).toFixed(2) +'%'}</p>
                </div>

            </div>
            <div>
            {item.items.map((stuff, index) => {
                return(
                    <JackpotItem
                        stuff={stuff}
                    />
                )
            })}
            </div>
            
        </div>
    );
  };

  function Profile(props) {
    const handleClick = () => socket.emit('loadinventory')
    
    const dispatch = useDispatch();
    const jackpotTimer = Number(useSelector(state => state.jackpot.jackpotTimer));

    const flips = useSelector((state) => state.shop.flips)
    const [images, setImages] = useState([]);
    const [roll, setRoll] = useState({right: "0px"});
    const [rolling, setRolling] = useState(false);
    const [display, setDisplay] = useState(1);
    const [cfFocus, setCfFocus] = useState(null); //we need to rework this so that we arent constantly looping flips
    const [black, setBlack] = useState(50);
    const [red, setRed] = useState(50);
    const [CFtotal, setCFtotal] = useState(0);
    const [buttonPopup, setButtonPopup] = useState(false);
    const [buttonPopup2, setButtonPopup2] = useState(false);
    const [animations, setAnimations] = useState(['animation2160','animation1980']);
    const [jackpots, setJackpots] = useState([]);
    const [coinflips, setCoinflips] = useState([]);
    const [selectedJackpot, setSelectedJackpot] = useState({});
    const [jackpotItems, setJackpotItems] = useState({});
    const [jackpotWinnerImg, setJackpotWinnerImg] = useState({});
    //const [selectedCoinflip, setSelectedCoinflip] = useState({});
    useEffect(() => {
        socket.emit('userLoad')
        socket.on('userResponse', (data) => {
            setJackpots(data.data[0])
            setCoinflips(data.data[1])
        });
        socket.on('viewJackpot', (data) => { //eww gross make this general

            setJackpotItems(data);
            if(jackpotItems != {}){
                for(let i = 0; i<data.users.length; i++){
                    if(data.users[i].userID == data.winner){
                        setJackpotWinnerImg({image: data.users[i].profileImage, name: data.users[i].name})
                        break
                    }
                }
                setButtonPopup2(true)
            }
        });
        socket.on('viewCF', (data) => { //this way is more general
            //setSelectedCoinflip(data);
            dispatch(setFlips([data]))
            dispatch(setCurrentFlip(data._id));
            setButtonPopup(true)
        });
    }, []);
    return (
        <div>
            <CoinFlipDetails trigger={buttonPopup} setTrigger={setButtonPopup} cfFocus={cfFocus}>

            </CoinFlipDetails>
            <Popup trigger={buttonPopup2} setTrigger={setButtonPopup2}>
                <p>Winner: {jackpotWinnerImg.name}</p><img src={jackpotWinnerImg.image}></img>
                <div className="inventory">
                {jackpotItems.hash ? jackpotItems.users.map((item, index) => {
                      return (
                        
                        <JackpotEntry
                          item={item} jackpotTotal={jackpotItems.totalPrice ? jackpotItems.totalPrice : item.totalPrice}
                        />
                        
                        //replace this with a react element!!!!!
                        //<div id={item.assetid} className="item"><img src={'https://community.cloudflare.steamstatic.com/economy/image/' + item.icon_url}></img><p>{item.market_hash_name}</p><p>{' $' + item.price}</p></div>
                      )
                    }): ''}
                </div>
                <p>Ticket: {jackpotItems.ticket}</p>
            </Popup>
            <div>Jackpots:</div>
            <div className="flipz">
                {jackpots.length > 0 ? jackpots.map((item, index) =>{
                    //console.log(item);
                    return(
                        <ListJackpots
                            key={index}
                            item={item}
                        />
                    )
                }) : 'None'}
            </div>
            <div>Coinflips:</div>
            <div className="flipz">
                {coinflips.length > 0 ? coinflips.map((item, index) =>{
                    //console.log(item);
                    return(
                        <ListCoinflips
                            key={index}
                            item={item}
                        />
                    )
                }) : 'None'}
            </div>
        </div>
    )
}

export default Profile