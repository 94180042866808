import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Context from './context'
import Inventory from './inventory'
import { Provider } from "react-redux";
import { store } from "./redux/store";
import 'bootstrap/dist/css/bootstrap.min.css';


ReactDOM.render(
  
  <React.StrictMode>
    <Provider store={store}>
    <Context>
      <App/>
    </Context>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
