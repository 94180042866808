import './Progress.css';
import React from 'react'
import {socket} from '../Socket'
import { useSelector, useDispatch } from "react-redux";
import { useState, useContext, useEffect } from 'react';
import {setJackpotTimer} from '../redux/Jackpot'
import line from '../images/line.png'
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {
    resetVars,
    setBalance,
    searchForItem,
    updateInventory,
    setSelected,
    removeSelected,
    toggleDescending,
    setMinAmount,
    setMaxAmount,
    setBonus,
    setTradeData,
    closeTradePopup,
    setJackpotItems
  } from "../redux/shop";



const JackpotItem = ({ stuff }) => {
    const selected = useSelector(state => state.shop.selected);
    const dispatch = useDispatch();
    return (
        <div title={stuff.market_hash_name} className='jackpotItem'>
            <img src={'https://community.cloudflare.steamstatic.com/economy/image/' + stuff.image}></img>
            <p>{stuff.price}</p>
            
        </div>
    );
  };

const JackpotEntry = ({ item }) => {
    const selected = useSelector(state => state.shop.selected);
    const jackpotItems = useSelector((state) => state.shop.jackpotItems)
    const dispatch = useDispatch();
    //console.log(item);
    return (
        <div>
            <div className="jackpotEntryHeader">
                <div className="center">
                    <img className="jackpotUser" src={item.profileImage}></img>
                    <p>{item.name + ' $'+item.totalPrice.toFixed(2)+' | '+(item.totalPrice/jackpotItems.totalPrice*100).toFixed(2) +'%'}</p>
                </div>

            </div>
            <div>
            {item.items.map((stuff, index) => {
                return(
                    <JackpotItem
                        stuff={stuff}
                    />
                )
            })}
            </div>
            
        </div>
    );
  };





function Progress(props) {
    const handleClick = () => socket.emit('loadinventory')
    const dispatch = useDispatch();
    const jackpotTimer = Number(useSelector(state => state.jackpot.jackpotTimer));
    const jackpotItems = useSelector((state) => state.shop.jackpotItems)
    const [images, setImages] = useState([]);
    const [roll, setRoll] = useState({right: "0px"});
    const [rolling, setRolling] = useState(false);
    const [display, setDisplay] = useState(1);
    const [winDat, setWinDat] = useState({});
    useEffect(() => {
        socket.emit('jackPotLoad');
        socket.on('JackpotTime', (data) =>{
            console.log(data.timeRemaining)
            dispatch(setJackpotTimer(data.timeRemaining>0 ? data.timeRemaining : 120));
            if(data.timeRemaining == 0){
                setRolling(true)
                dispatch(setJackpotItems({}))
            }
        });
        socket.on('jackpotWinner', (data) => {
            /*let imageArr = []
            for (let i = 0; i<300; i++){
                if(i == 239){
                    imageArr.push('https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/54/54f68c38efea26c3b46611c57f831162ebf863e1_medium.jpg')
                }else{
                    imageArr.push('https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/35/35eb986ddec52be02c02300665ccebfaa534171a_medium.jpg')
                }
                
            }*/
            setWinDat(data)
            setImages(data.images)
            setDisplay(2)
            var temp = 0
            let timerInterval = setInterval(() => {
                if (temp==1){
                    setRoll({right: "15000px"})
                }
                if (temp==30){
                    setRoll({display: "none"})
                    setDisplay(3)
                }
                if (temp==45){
                    setDisplay(1)
                    setRoll({right: "0px"})
                    setRolling(false)
                    clearInterval(timerInterval)
                }
                temp++
            }, 300);
            
            
        })
    }, []);
    return (
        <div className="timer">
	     <div className='siteInfo'>
                RustyCargo has NO TAXES!<br></br>
                If you enjoy your time at RustyCargo, please consider donating. We want to continue to improve our site 
                and provide you with new and cool features.<br></br>
                We are keeping track of your donations and plan to implement some cool stuff based on donation tiers in the future!<br></br>
                If server costs far exceed our income from donations we will either need to shut down the website or switch to taxing your bets.
            </div>
            <div className="base-timer">
                <svg className="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <g className="base-timer__circle">
                    <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45" />
                    <path
                        id="base-timer-path-remaining"
                        stroke-dasharray={(jackpotTimer/120*283) - (1/120) * (1 - jackpotTimer/120*283)+" 283"}
                        className="base-timer__path-remaining "
                        d="
                        M 50, 50
                        m -45, 0
                        a 45,45 0 1,0 90,0
                        a 45,45 0 1,0 -90,0
                        "
                    ></path>
                    </g>
                </svg>
                <span id="base-timer-label" className="base-timer__label">
                    {jackpotTimer}
                </span>

            

            </div>
            <div id="jackpotRoll" style={display==2 || display==3 ? {} : {display: 'none'} }>
                <div style={display==2 ? {} : {display: "none"}}>
                    <img className="line img" src={line}></img>
                    <div id="roll-Imgs" style={roll}>{images.length > 0 ? images.map((item, index) => {
                        return(
                            <img className='img' src={item}></img>
                        )
                    }) : ''}
                   </div>
                   
                </div>
                <div id="winnerInfo" style={display==3 ? {} : {display: "none"}}>
                    <img src={winDat.profileImage}></img>
                    {winDat.name + ' won with a ' + winDat.percent + '% chance!'}
                </div>
            </div>
            <div className="jackpotDeposit" onClick={() => {
                console.log('clicked it boiiii!')
                
                handleClick();
                props.openInventory(true);
            }}>
                <p className='m-auto'>Enter Jackpot</p>
            </div>
            
            <div id="jackpotEntries" className="jackpotEntries">
                <Row className={'jackpotEntriesText'}>
                    
                    <Col xs={4}>
                        {jackpotItems.totalPrice ? '$' + jackpotItems.totalPrice.toFixed(2) : '$0.00'}
                    </Col>
                    <Col>
                        {rolling ? 'Rolling!' : 'Current Pot'}
                    </Col>
                    <Col>
                        {//stuff go here
                        }
                    </Col>  
                </Row>
                
                <div>
                    {jackpotItems.hash ? jackpotItems.users.map((item, index) => {
                      return (
                        
                        <JackpotEntry
                          item={item}
                        />
                        
                        //replace this with a react element!!!!!
                        //<div id={item.assetid} className="item"><img src={'https://community.cloudflare.steamstatic.com/economy/image/' + item.icon_url}></img><p>{item.market_hash_name}</p><p>{' $' + item.price}</p></div>
                      )
                    }): ''}
                </div>
            </div>
        </div>
    )
}

export default Progress
