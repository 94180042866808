import './App.css';
import Popup from './components/Popup';
import Navbar from './components/Navbar';
import Coinflip from './components/Coinflip';
import Profile from './components/Profile';
import { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { myContext} from './context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {InventoryContext } from './inventory';
import { socket } from "./Socket";
import Chat from './components/Chat'
import Chatbar from './components/ChatBar'
import Progress from './components/Progress'
import rust from './images/rust.png';
import rust_2 from './images/rust_2.png'
import black from './images/black.png'
import silver from './images/silver.png'
import treebackground from './images/treebackground.png'
import { Link } from 'react-router-dom';
//FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faFaucet, faGift, faQuestionCircle, faUser, faCoins, faSignOutAlt, faCaretDown, faComment, faVolumeMute, faChevronRight, faArrowUp, faCheck, faBomb, faBalanceScale } from '@fortawesome/free-solid-svg-icons'
import { faDiscord, faSteam, faTwitter } from "@fortawesome/free-brands-svg-icons";


import {
  resetVars,
  setBalance,
  searchForItem,
  updateInventory,
  setSelected,
  autoSelect,
  removeSelected,
  setTradeTotal,
  removeTradeTotal,
  clearTradeTotal,
  clearSelected,
  toggleDescending,
  setMinAmount,
  setMaxAmount,
  setBonus,
  setTradeData,
  closeTradePopup,
  setJackpotItems
} from "./redux/shop";

library.add(faCheckSquare, faGift, faUser, faQuestionCircle, faFaucet, faCoins, faSignOutAlt, faDiscord, faTwitter, faSteam, faCaretDown, faComment, faVolumeMute, faChevronRight, faArrowUp, faCheck, faBomb, faBalanceScale)



/*function fetchAPI(url, callback) {
  return fetch(url,{credentials: 'include'})
    .then((res) => res.json())
    .then(callback);
}*/



function DepositPost(items,callback){
  // POST request using fetch inside useEffect React hook
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ items: items  }),
      credentials: 'include'
  };
  return fetch('/deposit', requestOptions)
      .then(response => response.json())
      .then(callback);

// empty dependency array means this effect will only run once (like componentDidMount in classes)
};

function TradeLink(tradelink,callback){
  // POST request using fetch inside useEffect React hook
  /*
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ tradelink: tradelink }),
      credentials: 'include'
  };
  return fetch('http://rustypot.com/tradelink/', requestOptions)
      .then(response => response.json())
      .then(callback);
  */
  socket.emit('tradelink', {tradelink: tradelink})
// empty dependency array means this effect will only run once (like componentDidMount in classes)
};

//function GetInventory(){}

const Item = ({ item }) => {
  const selected = useSelector(state => state.shop.selected);
  const dispatch = useDispatch();
  return (

    <div className={selected.indexOf(item.assetid) !== -1 ? "itemContainer active" : "itemContainer"} onClick={() => 
      {
        //console.log(chosen)
        console.log(item.quantity)
        if (selected.indexOf(item.assetid) !== -1){
          console.log('removing')
          //chosen.filter(i => console.log('ITEM ' +i))
          //console.log('assetid ' +item.assetid)
          dispatch(removeSelected(item.assetid))
          dispatch(removeTradeTotal(item.price))
          //setActive(false);
          //console.log('chosen ' + chosen)
        }else{

          //console.log('adding')
          //console.log('assetid ' + item.assetid)
          dispatch(setSelected(item.assetid));
          dispatch(setTradeTotal(item.price));
          //console.log(chosen)
          //setActive(true);
        }
    }}>
      
      <img src={'https://community.cloudflare.steamstatic.com/economy/image/' + item.icon_url}></img>
      <p>{item.market_hash_name}</p>
      <p>{' $' + item.price.toFixed(2)}</p>

    </div>
  );
};

const CustomToastWithLink = (message, link, linkMessage) => (
  <div>
    {message}<br></br>
    <a href={'https://www.' + link} target="_blank">{linkMessage}</a>
  </div>
);

function App() {
  const [buttonPopup, setButtonPopup] = useState(false);
  const [inventoryPopup, setInventoryPopup] = useState(false);
  const [donatePopup, setDonatePopup] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [active, setActive] = useState(false);
  const [data, setData] = useState(null);
  const [trade, setTrade] = useState(null);
  const [TOS, setTOS] = useState(false);
  const chosen = useSelector(state => state.shop.selected)
  const tradeData = useSelector(state => state.shop.tradeData)
  const tradeTotal = useSelector(state => state.shop.tradeTotal)
  const userObject = useContext(myContext);
  const userInventory = useSelector(state => state.shop.inventory);
  const messages = useSelector((state) => state.chat.messages);
  const jackpotItems = useSelector((state) => state.shop.jackpotItems)
  //console.log(userInventory)
  const dispatch = useDispatch();
  const handleJackpotDeposit = () => { //send assetID and market_hash_name to check price in databse
    let itemArr = []
    for (let i = 0; i<chosen.length; i++){
      for (let j = 0; j<userInventory.length; j++){
        console.log('your inv')
        console.log(chosen)
        if(userInventory[j].assetid == chosen[i]){
          
          itemArr.push({assetid: chosen[i].substring(0, chosen[i].length-1), market_hash_name: userInventory[j].market_hash_name});
          
          break;
        }
      }
    }
    socket.emit('jackpotDeposit',{items: itemArr})
  }
  const handleCoinflipDeposit = () => { //send assetID and market_hash_name to check price in databse
    
    let itemArr = []
    for (let i = 0; i<chosen.length; i++){
      for (let j = 0; j<userInventory.length; j++){
        console.log('your inv')
        console.log(chosen)
        if(userInventory[j].assetid == chosen[i]){
          
          itemArr.push({assetid: chosen[i].substring(0, chosen[i].length-1), market_hash_name: userInventory[j].market_hash_name});
          
          break;
        }
      }
    }
    if(itemArr.length > 0){
      //alert(tradeData.hash)
      if(tradeData.hash){
        socket.emit('coinflipDeposit',{items: itemArr, hash: tradeData.hash})
      }
      else{
        let pick = document.getElementById('rust_2').className == 'picked' ? 1 : 0
        socket.emit('coinflipDeposit',{items: itemArr, pick: pick})
      }
      dispatch(setTradeData({}))
      dispatch(clearSelected())
      dispatch(clearTradeTotal())
    }
    
  }
  const handleDonate = () => { //send assetID and market_hash_name to check price in databse
    let itemArr = []
    for (let i = 0; i<chosen.length; i++){
      for (let j = 0; j<userInventory.length; j++){
        console.log('your inv')
        console.log(chosen)
        if(userInventory[j].assetid == chosen[i]){
          
          itemArr.push({assetid: chosen[i].substring(0, chosen[i].length-1), market_hash_name: userInventory[j].market_hash_name});
          
          break;
        }
      }
    }
    socket.emit('coinflipDeposit',{items: itemArr, donate: true})
  }
  const handleJoinCoinflip = (tradeData) => { //send assetID and market_hash_name to check price in databse
    let itemArr = []
    for (let i = 0; i<chosen.length; i++){
      for (let j = 0; j<userInventory.length; j++){
        console.log('your inv')
        console.log(chosen)
        if(userInventory[j].assetid == chosen[i]){
          
          itemArr.push({assetid: chosen[i].substring(0, chosen[i].length-1), market_hash_name: userInventory[j].market_hash_name});
          
          break;
        }
      }
    }
    if(itemArr.length > 0){
      if(tradeData){
        socket.emit('coinflipDeposit',{items: itemArr, tradeData: tradeData})
      }
      else{

      }
    }
    
  }
  
  //console.log(userObject);

  const requestInventory = () => {
    socket.emit('loadinventory');
  }
  useEffect(() => {
	console.log(userObject);
    socket.emit('getMessage');
    socket.on('ErrorMessage', (data) => {
      //console.log('errormsg')
      if(data.link){
        toast.error(CustomToastWithLink(data.message, data.link, data.linkMessage),{position: toast.POSITION.BOTTOM_LEFT})
      }else{
        toast.error(data.message, {position: toast.POSITION.BOTTOM_LEFT})
      }
    });
    socket.on('SucessMessage', (data) => {
      //console.log('errormsg')
      if(data.link){
        toast.success(CustomToastWithLink(data.message, data.link, data.linkMessage),{position: toast.POSITION.BOTTOM_LEFT})
      }else{
        toast.success(data.message, {position: toast.POSITION.BOTTOM_LEFT})
      }
      
    });
    socket.on('loadinventoryResponse', (data) => {
      //console.log('invResponse')
      console.log(data)
      let tempInv = []
      for(let i = 0; i<data.length; i++){
        for(let j = 0; j<data[i].amount; j++){
          tempInv.push({
            assetid: data[i].assetid + j,
            price: data[i].price,
            icon_url: data[i].icon_url,
            market_hash_name: data[i].market_hash_name
          })
          //tempInv[tempInv.length-1].assetid=data[i].assetid + j
        }
      }
      dispatch(updateInventory(tempInv))
    });
    socket.on('jackpotDeposit', (data) => {
      console.log(data)
      dispatch(setJackpotItems(data))
      console.log(jackpotItems)
    })
    socket.on('JackpotReset', (data) => {

    });
    
  }, []);
  return (
    
    <Router>
      <Chat/>
      <ToastContainer />
      <Popup trigger={donatePopup} setTrigger={setDonatePopup}>
        <input id="tradelink" type="text" placeholder={userObject ? userObject.tradelink ? userObject.tradelink : "Steam Trade Link" : "Steam Trade Link"}></input>
        <button onClick={() => {
          TradeLink(document.getElementById("tradelink").value, (res) => {
            setTrade(res.tradelink)
            requestInventory();
          })
        }}>Save</button>
        <a href="http://steamcommunity.com/my/tradeoffers/privacy" target="_blank">Find your trade link here</a>
        <br></br>
        <div className="inventory">
          {userInventory.length > 0 ? userInventory.map((item, index) => {
            return (
              
              <Item
                item={item}
              />
              
              //replace this with a react element!!!!!
              //<div id={item.assetid} className="item"><img src={'https://community.cloudflare.steamstatic.com/economy/image/' + item.icon_url}></img><p>{item.market_hash_name}</p><p>{' $' + item.price}</p></div>
            )
          }) : 'No items found! Make sure you have saved your trade link and that your profile is set to public'}
        </div>
        <button onClick={handleDonate}>Deposit ${tradeTotal.toFixed(2)}</button>
      </Popup>
      
      <div className="App">
        <main>
        
          
            <Navbar setTrigger={setButtonPopup} setClicked={setClicked} clicked={clicked} userObject={userObject} openInventory={setInventoryPopup} openDonate={setDonatePopup} requestInventory={requestInventory}></Navbar>
            
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
              <p>By using this website you acknowledge that you must be at least 18 years old, and agree to our terms of service.</p>
              <Link to="/TOS">Click to view TOS</Link><br></br>
              <a href="http://rustycargo.com:3001/auth/steam">Continue sign in</a>
            </Popup>
            <Switch>
              
              <Route exact path="/">
                <Progress openInventory={setInventoryPopup} openLogin={setButtonPopup}/>
                <Popup trigger={inventoryPopup} setTrigger={setInventoryPopup}>
                  <input id="tradelink" type="text" placeholder={userObject ? userObject.tradelink ? userObject.tradelink : "Steam Trade Link" : "Steam Trade Link"}></input>
                  <button onClick={() => {
                    TradeLink(document.getElementById("tradelink").value, (res) => {
                      setTrade(res.tradelink)
                      requestInventory();
                    })
                  }}>Save</button>
                  <a href="http://steamcommunity.com/my/tradeoffers/privacy" target="_blank">Find your trade link here</a>
                  <br></br>
                  <div className="inventory">
                    {userInventory.length > 0 ? userInventory.map((item, index) => {
                      return (
                        
                        <Item
                          item={item}
                        />
                        
                        //replace this with a react element!!!!!
                        //<div id={item.assetid} className="item"><img src={'https://community.cloudflare.steamstatic.com/economy/image/' + item.icon_url}></img><p>{item.market_hash_name}</p><p>{' $' + item.price}</p></div>
                      )
                    }) : 'No items found! Make sure you have saved your trade link and that your profile is set to public'}
                  </div>
                  <button onClick={handleJackpotDeposit}>Deposit ${tradeTotal.toFixed(2)}</button>
                  
                </Popup>
              </Route>
              
              <Route exact path='/profile'>
                    <Profile></Profile>
              </Route>

              <Route exact path='/coinflip'>
                    <Coinflip openInventory={setInventoryPopup} tradeData={tradeData} userObject={userObject}/>
                    
                      <Popup trigger={inventoryPopup} setTrigger={setInventoryPopup}>
                        <div className='priceRange' style={tradeData.hash ? {color: 'white'} : {display: 'none'}}>
                          Deposit {(tradeData.price*.45/.55).toFixed(2)} - {(tradeData.price*.55/.45).toFixed(2)} to bet!
                        </div>
                        <div className='pick' style={tradeData.hash ? {display: 'none'} : {}}>
                            <img id='rust' src={rust} onClick={() => {
                              document.getElementById('rust').classList.add('picked')
                              document.getElementById('rust_2').className=''
                            }}></img>
                            <img id='rust_2' src={rust_2} onClick={() => {
                              document.getElementById('rust_2').classList.add('picked')
                              document.getElementById('rust').className=''
                            }}></img>
                        </div>
                        <input id="tradelink" type="text" placeholder={userObject ? userObject.tradelink ? userObject.tradelink : "Steam Trade Link" : "Steam Trade Link"}></input>
                        <button onClick={() => {
                          TradeLink(document.getElementById("tradelink").value, (res) => {
                            setTrade(res.tradelink)
                            requestInventory();
                          })
                        }}>Save</button>
                        <a href="http://steamcommunity.com/my/tradeoffers/privacy" target="_blank">Find your trade link here</a>
                        <br></br>
                        <div className="inventory">
                          {userInventory.length > 0 ? userInventory.map((item, index) => {
                            //add stuff to array depending on the 'amount'
                            return (
                              
                              <Item
                                item={item}
                              />
                              
                              //replace this with a react element!!!!!
                              //<div id={item.assetid} className="item"><img src={'https://community.cloudflare.steamstatic.com/economy/image/' + item.icon_url}></img><p>{item.market_hash_name}</p><p>{' $' + item.price}</p></div>
                            )
                          }) : 'No items found! Make sure you have saved your trade link and that your profile is set to public'}
                        </div>
                
                        <button className='pBtn' onClick={() => {handleCoinflipDeposit(); setInventoryPopup(false);}} disabled={
                          tradeData.hash ? tradeData.price*.45/.55 < tradeTotal && tradeTotal < tradeData.price*.55/.45 ? false : true : false
                        }>Deposit ${tradeTotal.toFixed(2)}</button>
                        <button className='pBtn' onClick={() => dispatch(autoSelect({total: tradeData.price, arr: userInventory.length>0 ? userInventory : [{flag: 1}]}))} style={tradeData.hash ? {} : {display: 'none'}}>Auto Select</button>
                      </Popup>
                    
              </Route>
		               <Route exact path='/TOS'>
                <br></br>
                <div className="tos">
                  No individual under the age of eighteen (18) may use RustyCargo, regardless of any consent from a parent or guardian to use RustyCargo. You need a supported Web browser to access RustyCargo.
                  <br></br><br></br>
                  You acknowledge and agree that RustyCargo may cease to support a given Web browser and that your continuous use of RustyCargo will require you to download a supported Web browser. You also acknowledge and agree that the performance of RustyCargo is incumbent on the performance of your computer equipment and your Internet connection.
                  <br></br><br></br>
                  You agree to sign on and register for RustyCargo through your Steam account provided by Valve Corporation. You are solely responsible for managing your account and password and for keeping your password confidential. You are also solely responsible for restricting access to your account.
                  <br></br><br></br>
                  You agree that you are responsible for all activities that occur on your account or through the use of your password by yourself or by other persons.
                  <br></br><br></br>
                  <b>As a condition to your use of RustyCargo, you agree not to:</b>
                  <br></br>
                  (a) impersonate or misrepresent your affiliation with any person or entity;
                  <br></br>
                  (b) access, tamper with, or use any non-public areas of RustyCargo or RustyCargo�s computer systems;
                  (c) attempt to probe, scan, or test the vulnerability of RustyCargo or any related system or network or breach any security or authentication measures used in connection with RustyCargo and such systems and networks;<br></br>
                  (d) attempt to decipher, decompile, disassemble, reverse engineer or otherwise investigate any of the software or components used to provide RustyCargo;<br></br>
                  (e) harm or threaten to harm other users in any way or interfere with, or attempt to interfere with, the access of any user, host or network, including without limitation, by sending a virus, overloading, flooding, spamming, or mail-bombing RustyCargo;<br></br>
                  (f) provide payment information belonging to a third party;<br></br>
                  (g) use RustyCargo in an abusive way contrary to its intended use, to RustyCargo policies and instructions and to any applicable law;<br></br>
                  (h) systematically retrieve data or other content from RustyCargo to create or compile, directly or indirectly, in single or multiple downloads, a collection, compilation, database, directory or the like, whether by manual methods, through the use of bots, crawlers, or spiders, or otherwise;<br></br>
                  (i) infringe third party intellectual property rights when using or accessing RustyCargo, including but not limited to in making available virtual items by using RustyCargo; and (j) make use of, promote, link to or provide access to materials deemed by RustyCargo at its sole discretion to be offensive or cause harm to RustyCargo�s reputation, including, but not limited to, illegal content and pornographic content and content deemed offensive or injurious to RustyCargo (such as Warez sites, IRC bots and bittorent sites).<br></br>
                  <br></br>
                  <b>Termination</b><br></br>
                  We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms of Service.<br></br>
                  All provisions of the Terms of Service which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.<br></br>
                  We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms of Service.<br></br>
                  Upon termination, your right to use RustyCargo will immediately cease. If you wish to terminate your account, you may simply do so by discontinuing your use of RustyCargo.<br></br>
                  <br></br><br></br>
                  <b>Links To Other Web Sites</b><br></br>
                  Our Service may contain links to third-party web sites or services that are not owned or controlled by RustyCargo.<br></br>
                  RustyCargo has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that RustyCargo shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.<br></br>
                  We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.<br></br>
                  <br></br><br></br>
                  <b>No warranties</b><br></br>
                  This website is provided �as is� without any representations or warranties, express or implied. www.rustycargo.com makes no representations or warranties in relation to this website or the information and materials provided on this website. Without prejudice to the generality of the foregoing paragraph, www.rustycargo.com does not warrant that: this website will be constantly available, or available at all; or the information on this website is complete, true, accurate or non-misleading. Nothing on this website constitutes, or is meant to constitute, advice of any kind. If you require advice in relation to any legal, financial or medical matter you should consult an appropriate professional.
                  <br></br><br></br>
                  <b>Affiliation</b><br></br>
                  We are in NO WAY affiliated with or endorsed by the FacePunch, Rust, Steam or any other trademarks of the Valve corporation.
                  <br></br><br></br>
                  <b>Returns and Refunds Policy</b><br></br>
                  We do not issue refunds for digital products once the order is confirmed and the product is sent. We recommend contacting us for assistance if you experience any issues receiving or downloading our products.
                  <br></br><br></br>
                  <b>UI / Interface Errors</b><br></br>
                  Interfaces glitches/manipulation in no way entitles the user to a certain item. All outcomes are generated by our random and awarded as such. The user agrees that the outcome will be determined by random system outcome and any user interface errors do not entitle the user to that item.
                  <br></br><br></br>
                  <b>Bots and Steam API</b><br></br>
                  The trade offer request sent by our bots are subject to Steam Guard authentication and the buyer must have Steam Guard activated for at least 15 days on their smartphone so the trade is completed. Note that this is a Steam restriction. The skins won't expire but the user need to wait for that period to end.
                  <br></br><br></br>
                  <b>Governing Law</b><br></br>
                  All inquiries relating to the construction, validity, enforcement and interpretation of these Terms shall be governed by and construed and enforced in accordance with the laws of the State of Virginia.
                  <br></br><br></br>
                  In the event of any dispute arising between RustCargo LLC and you, the parties will make best efforts to solve such dispute in good faith within a reasonable time. In case that you and RustCargo LLC will not reach an amicable solution within a reasonable period (of no less than 30 days as of the commencement of the amicable dispute resolution process), you or RustCargo LLC may seek legal resolution with the competent courts situated in Delaware, and such courts shall have sole and exclusive jurisdiction to resolve such dispute.
                </div>
                
              </Route>
            </Switch>
        </main>
      </div>
    </Router>
  );
}

export default App;
