import {React, useEffect} from 'react'
import { socket } from '../Socket';
import './CoinFlipDetails.css'
import { useSelector, useDispatch } from "react-redux";
import { useState, useContext} from 'react';
import {
    resetVars,
    setBalance,
    searchForItem,
    updateInventory,
    setSelected,
    removeSelected,
    toggleDescending,
    setMinAmount,
    setMaxAmount,
    setBonus,
    setTradeData,
    clearSelected,
    closeTradePopup,
    setCurrentFlip,
    editFlip
  } from "../redux/shop";
import rust from '../images/rust.png';
import rust_2 from '../images/rust_2.png'

const Citem = ({ stuff }) => {
    return (
        <div className='item' title={stuff.market_hash_name}>
            <img src={'https://community.cloudflare.steamstatic.com/economy/image/' + stuff.image}></img>
            <p>${stuff.price.toFixed(2)}</p>
        </div>
    );
};


function CoinFlipDetails(props) {
    const dispatch = useDispatch();
    const flipDict = useSelector((state) => state.shop.flipDict)
    const currentFlip = useSelector((state) => state.shop.currentFlip)
    const [test, setTest] = useState('');
    const [botPopup, setBotPopup] = useState(false);
    const [animations, setAnimations] = useState(['animation2160','animation1980']);

    const handleBotFlip = () => { // market_hash_name to database
        socket.emit('botflip',{hash: flipDict[currentFlip].hash, percent: 'lo'})
    }

    return (props.trigger) ? (
        <div className="CF-popup">
            <div className="CF-inner">
                <button className="close-btn" onClick={() => {
                    props.setTrigger(false)
                    }}>X</button>
                
                <div className='Creator'>
                    <img src={flipDict[currentFlip].creator.profileImage}></img>
                    <img src={flipDict[currentFlip].creator.pick == 0 ? rust : rust_2} style={{height: "50px", width: "50px"}}></img>
                    <p>{flipDict[currentFlip].creator.displayName}</p>
                    
                </div>
                <div className='Opponent'>
                    <img src={flipDict[currentFlip].creator.pick == 1 || flipDict[currentFlip].creator.pick == undefined  ? rust : rust_2} style={{height: "50px", width: "50px"}}></img>
                    <img src={flipDict[currentFlip].opponent ? flipDict[currentFlip].opponent.profileImage : ''}></img>
                    <p>{flipDict[currentFlip].opponent ? flipDict[currentFlip].opponent.name : ''}</p>
                </div>
                

                <div id="coin-flip-cont" style={{display:'flex'}}>
                    <div id='countdown' className='m-auto' style={flipDict[currentFlip].winner == undefined ? {} : {display: 'none'}}>
                        {flipDict[currentFlip].status=='Open'?
                            'Waiting for opponent...':
                            flipDict[currentFlip].status=='Joining'?
                                'Joining \n'+flipDict[currentFlip].timeRemaining:
                                flipDict[currentFlip].timeRemaining}
                    </div>
                    <div id="coin" style={flipDict[currentFlip].winner != undefined ? {} : {display: 'none'}}>
                        <div className="front" style={flipDict[currentFlip].winner == 1 ? {transform: "translateZ(-1px) rotateY(180deg)"} : {transform: "translateZ(1px)"}}></div>
                        <div className="back" style={flipDict[currentFlip].winner == 0 ? {transform: "translateZ(-1px) rotateY(180deg)"} : {transform: "translateZ(1px)"}}></div>
                    </div>
                </div>

                <div className='creatorItems'>
                    <div className='header'>
                        {'$'+flipDict[currentFlip].creator.total.toFixed(2) + (flipDict[currentFlip].opponent ? ' | ' + (flipDict[currentFlip].creator.total/(flipDict[currentFlip].creator.total+flipDict[currentFlip].opponent.totalPrice)*100).toFixed(2) + '%' : '')}
                    </div>
                    <div className='items'>
                        {flipDict[currentFlip].creator.items.map((stuff, index) => {
                            return(
                                <Citem 
                                    stuff={stuff}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className='opponentItems'>
                    <div className='header'>
                        {flipDict[currentFlip].opponent ? '$'+flipDict[currentFlip].opponent.totalPrice.toFixed(2) + ' | ' + (flipDict[currentFlip].opponent.totalPrice/(flipDict[currentFlip].creator.total+flipDict[currentFlip].opponent.totalPrice)*100).toFixed(2) + '%' : ''}
                    </div>
                    <div className='items'>
                    {flipDict[currentFlip].opponent ? flipDict[currentFlip].opponent.items.map((stuff, index) => {
                            return(
                                <Citem 
                                    stuff={stuff}
                                />
                            )
                        }) : ''}
                    </div>
                </div>
                <button className='botflipBtn' style={props.userObject && flipDict[currentFlip].creator.userid == props.userObject.id && flipDict[currentFlip].status == 'Open'?{}:{display: 'none'}} 
                    onClick={handleBotFlip}>
                        Call bot
                </button>
            </div>
        </div>
    ) :"";
}

export default CoinFlipDetails