import {
    createSlice
} from "@reduxjs/toolkit";

const slice = createSlice({
    name: "jackpot",
    initialState: {

        jackpotTimer: 120,

    },
    reducers: {
        setJackpotTimer: (state, action) => {
            state.jackpotTimer = action.payload;
        },
    },
    extraReducers: {},
}); 

export const {
    setJackpotTimer
} = slice.actions;

export default slice.reducer;