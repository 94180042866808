import React, { createContext, useState, useEffect } from 'react'


//user account info
export const myContext = createContext();
export default function Context(props) {
    const [userObject, setUserObject] = useState();
    useEffect(() => {
        fetch("/api", {credentials: 'include'})
          .then((res) => {
            if (res.status==200){
                res.json().then((data)=> setUserObject(data))
            }
              
          })
      }, [])
    return (
        <myContext.Provider value={userObject}>{props.children}</myContext.Provider>
    )
}

//user inventory info

