/* 
 *
 * shop.js 
 *
 */
import {
    createSlice
} from "@reduxjs/toolkit";


const slice = createSlice({
    name: "shop",
    initialState: {
        page: 'Deposit',
        k: 0,
        selected: [],
        selectedValue: 0,
        inventory: {},
        displayIv: {},
        sortedIv: {},
        invArr: [],
        jackpotItems: {},
        flips: [],
        flipDict: {},
        currentFlip: 0,
        descending: true,
        bonus: false,
        balance: 0,
        minAmount: 0,
        maxAmount: 10000,
        searched: { target: { value: "" } },
        tradeData: {},
        tradeTotal: 0
    },
    reducers: {
        resetVars: (state, action) => {
            state.page = action.payload;
            state.selected = {};
            state.k = 0;
            state.selectedValue = 0;
            state.inventory = {};
            state.displayIv = {};
            state.sortedIv = [];
            state.invArr = [];
            state.descending = true;
            state.bonus = false;
            state.balance = 0;
            state.minAmount = 0;
            state.maxAmount = 10000;
            state.searched = { target: { value: "" } };
            state.tradeData.enabled = false;
        },
        setBalance: (state, action) => {
            state.balance = action.payload * 100;
        },

        searchForItem: (state, action) => {

            if (action.payload != null) {
                state.searched = action.payload;
            }

            var temp = {};

            for (var item in state.inventory) {
                if (item.toLowerCase().includes(state.searched.target.value.toLowerCase())) {
                    if (state.inventory[item].price >= state.minAmount && state.inventory[item].price <= state.maxAmount) {
                        temp[item] = state.inventory[item];
                    }
                }
            }

            state.displayIv = temp;
            state.searched = action.payload;
        },

        updateInventory: (state, action) => {
            //state.inventory = action.payload;
            state.displayIv = action.payload;/*
            state.sortedIv = Object.keys(action.payload)
                .sort((a, b) => {
                    return state.descending
                        ? Number(action.payload[b].price) - Number(action.payload[a].price)
                        : Number(action.payload[a].price) - Number(action.payload[b].price);
                });
            let invArr = [];
            state.sortedIv.forEach(itemName => {
                invArr.push(state.displayIv[itemName]);
            })
            state.invArr = invArr;*/

            state.inventory = action.payload.sort((a,b) => {
                return b.price - a.price
            })
        },

        setJackpotItems: (state, action) => {
            state.jackpotItems = action.payload
        },
        setFlips: (state, action) => {
            let unsorted = [...action.payload]
            let sorted = unsorted.sort(function(a,b){
                if(a.status != b.status){
                    if(b.status != 'Finished'){
                        return 1
                    }else{
                        return -1
                    }
                }
                if(a.status != 'Finished'){
                    return  parseInt(a.createDate) - parseInt(b.createDate)
                }
                return  parseInt(b.createDate) - parseInt(a.createDate)
            })
            state.flips = sorted
            for(let i = 0; i<action.payload.length; i++){
                state.flipDict[action.payload[i]._id] = action.payload[i]
            }
        },
        editFlip: (state, action) => {
            let flag = -1;
            for(let i = 0; i<state.flips.length; i++){
                if(state.flips[i].hash == action.payload.hash ){
                    flag = i;
                    break;
                }
            }
            if(flag == -1){
                if(state.flips.length > 50){
                    state.flips.pop()
                }
                state.flips = [action.payload, ...state.flips]
                //console.log('payload')
                //console.log(action.payload)
                state.flipDict[action.payload['_id']] = action.payload
                
            }else{
                let tempData = state.flips[flag]
                Object.keys(action.payload).forEach(function(key) {
                    tempData[key] = action.payload[key];
                    //console.log(value);
                    
                });
                state.flips[flag] = tempData
                state.flipDict[tempData._id] = tempData
            }
            let sorted = state.flips.sort(function(a,b){
                if(a.status != b.status){
                    if(b.status != 'Finished'){
                        return 1
                    }else{
                        return -1
                    }
                }
                if(a.status != 'Finished'){
                    return  parseInt(a.createDate) - parseInt(b.createDate)
                }
                return  parseInt(b.createDate) - parseInt(a.createDate)
            })
            state.flips = sorted
        },
        removeFlips: (state, action) => {
            state.flips = action.payload
        },
        setCurrentFlip: (state, action) => {
            state.currentFlip = action.payload
            //state.flips = action.payload
        },
        setSelected: (state, action) => {
            state.selected = [...state.selected, action.payload]

        },
        autoSelect: (state, action) => { //given action.payload number, find items that are within +-5%

            let low = action.payload.arr[0]*.45/.55
            let items = state.inventory || [];
            let capacities = [[action.payload.total*.55/.45,12]]
            //console.log(items.length)
            //console.log(capacities)
            items = items.sort((a,b) =>{
                return b.price-a.price
            })
            let solutionIndexes = [];
            let currentTotal = 0;
            let currentCapacity = new Array(capacities.length);
            for(let i=0; i<items.length; i++){ //initiallize array
                solutionIndexes.push(0);
            }
            for(let i=0;i<capacities.length;i++){ //each knapsack
                currentCapacity[i] = capacities[i];
                for(let j=0; j<items.length; j++){
                    if(currentCapacity[i][0] == 0 || currentCapacity[i][1] == 0){
                        break; //stop looping, we can't add more
                    }
                    if(solutionIndexes[j] == 0 && items[j].price <= currentCapacity[i][0] && currentCapacity[i][1] > 0){
                        solutionIndexes[j] = i+1 //+1 so that our separate knapsacks are not indexed at 0
                        currentTotal += items[j].price
                        currentCapacity[i][0] -= items[j].price
                        currentCapacity[i][1] -= 1
                    }
                }
            }
            //console.log(solutionIndexes[0]);
            //console.log(currentTotal)
            //console.log(currentCapacity)
            let itemsNotAssigned = [];
            for(let i=0; i<items.length; i++){ //initiallize array
                if(solutionIndexes[i] == 0){
                    itemsNotAssigned.push(i);
                }
            }
            //console.log(itemsNotAssigned)
            //console.log(currentTotal)
            //console.log(currentCapacity)
            for(let i=0; i<items.length; i++){
                if(solutionIndexes[i] > 0){
                    for(let j=i+1; j<items.length; j++){
                        if(solutionIndexes[i] != solutionIndexes[j] && solutionIndexes[j]>0){
                            let hi = solutionIndexes[j]>solutionIndexes[i] ? j : i;
                            let lo = solutionIndexes[j]>solutionIndexes[i] ? i : j;
                            let diff = items[hi].price - items[lo].price;
                            //console.log(solutionIndexes[hi])
                            if(diff <= currentCapacity[solutionIndexes[lo]-1][0] && currentCapacity[solutionIndexes[hi]-1][0] + diff >= items[itemsNotAssigned[itemsNotAssigned.length-1]].price && currentCapacity[solutionIndexes[hi]-1][1] > 0){
                                let maxPriceIndex = 0;
                                for(let t = 0; t<itemsNotAssigned.length; t++){ //find hightest unused price that can fit the difference
                                    if(items[itemsNotAssigned[t]].price <= currentCapacity[solutionIndexes[hi]-1] + diff){
                                        maxPriceIndex = itemsNotAssigned[t];
                                        itemsNotAssigned.splice(t,1);
                                        break;
                                    }
                                }
                                currentCapacity[solutionIndexes[hi]-1][0] = currentCapacity[solutionIndexes[hi]-1][0] + diff - items[maxPriceIndex].price;
                                currentCapacity[solutionIndexes[lo]-1][0] = currentCapacity[solutionIndexes[hi]-1][0] - diff;
                                currentCapacity[solutionIndexes[hi]-1][1] -= 1

                                solutionIndexes[maxPriceIndex]=solutionIndexes[hi];
                                solutionIndexes[hi]=solutionIndexes[lo];
                                solutionIndexes[lo]=solutionIndexes[maxPriceIndex];
                                currentTotal += items[maxPriceIndex].price;
                            }
                        }
                    }
                }
            }
            //console.log(currentTotal)
            //console.log(currentCapacity)
            for(let i=items.length-1; i>=0; i--){
                if(solutionIndexes[i] > 0){
                    let tempCapacity = [currentCapacity[solutionIndexes[i]-1][0] + items[i].price, currentCapacity[solutionIndexes[i]-1][1]];
                    let arr = [];
                    let tempVal = 0
                    for (let j=0; j<items.length; j++){
                        if(tempCapacity[1] == 0){
                            break; // cant fit anything else in this array
                        }
                        if(solutionIndexes[j] == 0 && items[j].price <= tempCapacity[0]){
                            arr.push(j);
                            tempVal += items[j].price;
                            tempCapacity[0] = tempCapacity[0] - items[j].price;
                            tempCapacity[1] -= 1;
                        }
                    }
                    if(tempVal > items[i].price){
                        for(let k=0; k<arr.length; k++){
                            solutionIndexes[arr[k]] =  solutionIndexes[i];
                        }
                        currentCapacity[solutionIndexes[i]-1][0] = tempCapacity[0];
                        currentCapacity[solutionIndexes[i]-1][1] = tempCapacity[1];
                        solutionIndexes[i] = 0;
                        currentTotal += tempVal - items[i].price;
                    }
                }
            }
            state.selected=[];
            state.tradeTotal=0;
            //console.log(solutionIndexes);
            //console.log(currentTotal);
            //if(currentTotal <= capacities[0][0] && low <= currentTotal){ //change this line if we want multiple capacities
                for(let i=0; i<items.length; i++){
                    if(solutionIndexes[i] > 0){
                        //console.log(items[i])
                        state.selected.push(items[i].assetid)
                        state.tradeTotal += items[i].price
                    }
                }
            //}
        },
        setTradeTotal: (state, action) => {
            state.tradeTotal += action.payload

        },
        removeSelected: (state, action) => {
            state.selected=state.selected.filter(i => i !== action.payload)
        },
        removeTradeTotal: (state, action) => {
            state.tradeTotal -= action.payload
        },
        clearSelected: (state, action) => {
            state.selected=[]
        },
        clearTradeTotal: (state, action) => {
            state.tradeTotal=0
        },
        toggleDescending: (state, action) => {
            state.descending = !state.descending;
        },

        setMinAmount: (state, action) => {
            if (!action.payload) {
                state.minAmount = 0;
            } else {
                state.minAmount = action.payload;
            }
        },

        setMaxAmount: (state, action) => {
            if (!action.payload) {
                state.maxAmount = 100000;
            } else {
                state.maxAmount = action.payload;
            }
        },
        setBonus: (state, action) => {
            state.bonus = action.payload;
        },
        setTradeData: (state, action) => {
            if (state.page === "Withdraw") {
                let audio = new Audio("/audio/trade/withdraw.mp3");
                audio.play();
            }

            state.tradeData = action.payload;
            state.tradeData.enabled = true;
        },
        closeTradePopup: (state, action) => {
            state.tradeData.enabled = false;
        }
    },
    extraReducers: {},
});

export const {
    resetVars,
    setBalance,
    searchForItem,
    updateInventory,
    setSelected,
    autoSelect,
    clearSelected,
    removeSelected,
    setTradeTotal,
    clearTradeTotal,
    removeTradeTotal,
    toggleDescending,
    setMinAmount,
    setMaxAmount,
    setBonus,
    setTradeData,
    closeTradePopup,
    setJackpotItems,
    setFlips,
    editFlip,
    setCurrentFlip
} = slice.actions;

export default slice.reducer;