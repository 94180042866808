import './Coinflip.css';
import React from 'react';
import Popup from './Popup';
import {socket} from '../Socket'
import { useSelector, useDispatch } from "react-redux";
import { useState, useContext, useEffect } from 'react';
import {setJackpotTimer} from '../redux/Jackpot'
import line from '../images/line.png'
import rust from '../images/rust.png';
import rust_2 from '../images/rust_2.png'
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import {
    resetVars,
    setBalance,
    searchForItem,
    updateInventory,
    setSelected,
    removeSelected,
    toggleDescending,
    setMinAmount,
    setMaxAmount,
    setBonus,
    setTradeData,
    editFlip,
    closeTradePopup,
    setJackpotItems,
    setFlips,
    setCurrentFlip
  } from "../redux/shop";
import CoinFlipDetails from './CoinFlipDetails';

  const FlipItem = ({ stuff }) => {
    const selected = useSelector(state => state.shop.selected);
    const dispatch = useDispatch();
    return (
        <OverlayTrigger
            
            placement={'top'}
            overlay={
                <Tooltip id={`tooltip-top`}>
                    {stuff.market_hash_name + ' | ' + stuff.price.toFixed(2)}
                </Tooltip>
            }
        >
            <div className='coinflipItem'>
                <img src={'https://community.cloudflare.steamstatic.com/economy/image/' + stuff.image}></img>
            </div>
        </OverlayTrigger>
        
    );
  };


  const Flip = ({ item, openInventory, setCfFocus, setButtonPopup, userObject }) => {
    const selected = useSelector(state => state.shop.selected);
    const dispatch = useDispatch();
    //console.log('generating div')
    //console.log(item)
    //console.log('CFload DATA')
    return (
        
      <Row className={'flipEntry'} hash={item._id}  style={{display: item.status!='Cancelled'?'':'none'}}>
          <Col>
            <Row>
                <Col xs={4} style={{display: 'flex'}}>
                    <img className="profileImage" src={item.creator.profileImage}></img>
                    <img className="pickImage0" src={item.creator.pick == 0 ? rust : rust_2}></img>
                </Col>
                <Col className='d-flex'>
                    <div className={'m-auto'}>{item.creator.displayName}<br></br><p className='money'>${item.creator.total.toFixed(2)}</p></div>
                    
                </Col>
            </Row>
          </Col>
          <Col xs={8} className='d-flex'>
            <div className='m-auto d-flex'>
            {item.creator.items ? item.creator.items.map((item2, index) =>{
                    //console.log(item);
                    if(index<8){
                        return(
                            <FlipItem
                                stuff={item2}
                            />
                        )
                    }
                    else{
                        if(index==8)return(<p className='m-auto'>+{item.creator.items.length-6} items</p>)
                    }
                }) : 'NoFlips?'}
            </div>
          </Col>
          <Col className={'d-flex align-items-end'}>
            <p className='m-auto'>{item.status + ' '}{item.status!='Open' ? item.timeRemaining!=0 ? item.timeRemaining : '' : ''}</p>

            <button className='m-auto cfBtn' onClick={() => {
                socket.emit('loadinventory');
                dispatch(setTradeData({hash: item.hash, price: item.creator.total.toFixed(2)}));
                openInventory(true);
            }} disabled={item.status!='Open'} style={{display: userObject?userObject.id==item.creator.userid?'none':'':''}}>Join</button>
            <button className='m-auto cfBtn' onClick={() => {
                socket.emit('CancelCF',{hash: item.hash});
                console.log(userObject)
                console.log(item)
                console.log((item.createDate-Date.now())/(3600*1000))
            }} disabled={item.status!='Open' || (item.createDate-Date.now())/(3600*1000)>-2} style={{display: userObject?userObject.id==item.creator.userid?'':'none':'none'}}>Cancel</button>
            <button className='m-auto cfBtn' onClick={() => {
                //console.log('view ' + item._id)
                setButtonPopup(true);
                dispatch(setCurrentFlip(item._id));
            }}>
                View
            </button>
          </Col>
          
      </Row>
    );
  };


  function Coinflip(props) {
    const handleClick = () => socket.emit('loadinventory')
    
    const dispatch = useDispatch();
    const jackpotTimer = Number(useSelector(state => state.jackpot.jackpotTimer));
    const jackpotItems = useSelector((state) => state.shop.jackpotItems)
    const flips = useSelector((state) => state.shop.flips)
    const [images, setImages] = useState([]);
    const [roll, setRoll] = useState({right: "0px"});
    const [rolling, setRolling] = useState(false);
    const [display, setDisplay] = useState(1);
    const [cfFocus, setCfFocus] = useState(null); //we need to rework this so that we arent constantly looping flips
    const [black, setBlack] = useState(50);
    const [red, setRed] = useState(50);
    const [CFtotal, setCFtotal] = useState(0);
    const [buttonPopup, setButtonPopup] = useState(false);
    const [animations, setAnimations] = useState(['animation2160','animation1980']);
    useEffect(() => {
        socket.emit('coinflipLoad')
        socket.on('CFload', (data) => {
            //console.log('CFload DATA')

            dispatch(setFlips(data.coinflips))
            //console.log(data.coinflips)
        })
        socket.on('CFupdate', (data) => {
            //console.log('CFload DATA')
            
            dispatch(editFlip(data))
            //console.log(data)
            let doc = document.getElementById('coin')
            if(data.winner != undefined && doc){
                //console.log('winner')
                document.getElementById('coin').className="";
                setTimeout(function(){
                    document.getElementById('coin').className=animations[0];
                }, 100);
              }
        })
        socket.on('JackpotTime', (data) =>{
            //console.log(data.timeRemaining)
            dispatch(setJackpotTimer(data.timeRemaining>0 ? data.timeRemaining : 120));
            if(data.timeRemaining == 0){
                setRolling(true)
                dispatch(setJackpotItems({}))
            }
        });
        socket.on('JackpotTime', (data) =>{
            //console.log(data.timeRemaining)
            dispatch(setJackpotTimer(data.timeRemaining>0 ? data.timeRemaining : 120));
            if(data.timeRemaining == 0){
                setRolling(true)
                dispatch(setJackpotItems({}))
            }
        });
        socket.on('jackpotWinner', (data) => {
            let imageArr = []
            for (let i = 0; i<300; i++){
                if(i == 239){
                    imageArr.push('https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/54/54f68c38efea26c3b46611c57f831162ebf863e1_medium.jpg')
                }else{
                    imageArr.push('https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/35/35eb986ddec52be02c02300665ccebfaa534171a_medium.jpg')
                }
                
            }
            setImages(imageArr)
            setDisplay(2)
            var temp = 0
            let timerInterval = setInterval(() => {
                if (temp==1){
                    setRoll({right: "15000px"})
                }
                if (temp==30){
                    setRoll({display: "none"})
                    setDisplay(3)
                }
                if (temp==45){
                    setDisplay(1)
                    setRoll({right: "0px"})
                    clearInterval(timerInterval)
                }
                temp++
            }, 300);
            
            
        })
        socket.on('CFtotal', (data) => {
            setRed(data.red)
            setBlack(data.black)
            setCFtotal(data.total)
        })
    }, []);
    return (
        

        <div>
            <CoinFlipDetails trigger={buttonPopup} setTrigger={setButtonPopup} cfFocus={cfFocus} userObject={props.userObject}>

            </CoinFlipDetails>
            <div className="info">
                <div className="coinflipText">
                        <div className="row1">
                            
                        </div>
                        <div className="row2">
                            Coinflip
                        </div>
                        <div className="row3">
                            
                        </div>
                </div>
                <div>
                    <div className="row1">
                        <p>${CFtotal.toFixed(2)}</p>   
                    </div>
                    <div className="row2">
                        <p>Last 100 flips</p>
                        <p>Black {black} | Red {red}</p>
                    </div>
                    <div className="row3">
                        <div className='coinflipCreate' onClick={() => {
                            //console.log('clicked')
                            handleClick();
                            props.openInventory(true);
                        }}>Create Game</div>
                    </div>
                </div>
            </div>


            <Stack gap={2} className={'flipz'} style={{marginLeft: "0px", paddingLeft: '20px'}}>
                {flips ? flips.map((item, index) =>{
                    //console.log(item);
                    return(
                        <Flip
                            key={index}
                            item={item}
                            openInventory={props.openInventory}
                            setCfFocus={setCfFocus}
                            setButtonPopup={setButtonPopup}
                            userObject={props.userObject}
                        />
                    )
                }) : 'NoFlips?'}
            </Stack>
        </div>
        

        
    )
}

export default Coinflip